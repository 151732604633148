import React from 'react';
import { Input, Form, Modal,Button,Row,Col, Divider,Select } from 'antd';
import { FormWrapper } from '../../Style/style';
const OpticalInfoModal = ({ opticalInfo,visibilityList,opticalInfoModalActive,updateOpticalInfoRow,messages,toggleModal}) => {
  const [form1013] = Form.useForm();
  // React.useEffect(() => {
  //   form1012.setFieldsValue(electricalInfo)
  //  }, [electricalInfo])

  return (
  <>
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={opticalInfoModalActive}
      footer={            
      <Button  size="large" type="primary" onClick={() => toggleModal("opticalInfoModalActive",null)} >
        {messages["button.close"]}
      </Button>
      }
      onCancel={() => toggleModal("opticalInfoModalActive",null)}
      forceRender
      closable={false}
    >
       <FormWrapper style={{height:'auto'}}>
        <Form form={form1013}  initialValues={opticalInfo} >
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["genel.sira"]}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name='no'>
                <Input type='number' onChange={(eData)=>updateOpticalInfoRow("no",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["genel.visibility"]}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name='visibility'>
                <Select options={visibilityList.map(x=>{return({label:messages[x.label],value:x.value})})}  onSelect={(eData)=>updateOpticalInfoRow("visibility",eData)}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["product.label"] + " TR"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['label', `${0}`]}>
                <Input onChange={(eData)=>updateOpticalInfoRow("label",eData.target.value,0)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["product.label"]+ " EN"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['label', `${1}`]}>
                <Input onChange={(eData)=>updateOpticalInfoRow("label",eData.target.value,1)}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["product.spec"]+ " TR"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['value', `${0}`]}>
                <Input onChange={(eData)=>updateOpticalInfoRow("value",eData.target.value,0)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["product.spec"]+ " EN"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['value', `${1}`]}>
                <Input onChange={(eData)=>updateOpticalInfoRow("value",eData.target.value,1)}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </Modal>
  
  </>
  );
}

export  {OpticalInfoModal};
